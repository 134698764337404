/* stylelint-disable */
.container {
  align-items: center;
  background-color: var(--white);
  border: solid 1px #e5e5e5;
  border-radius: 4px;
  display: flex;
  width: 100%;
  height: 64px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  padding: 15px 29px 14px 10px;
}

.container:hover,
.container:focus,
.active {
  padding: 14px 26px 15px 10px;
  border: 2px solid #00b9ff;
}

.photo {
  width: 34px;
  height: 34px;
  border-radius: 34px;
}

.name {
  font-family: Avenir;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  width: 100%;
  color: var(--black);
  margin-left: 7px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.containerName {
  display: grid;
}

.jobTitle {
  font-family: Avenir;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  color: var(--black);
  margin-left: 7px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}

.unreadCount {
  width: 18px;
  height: 18px;
  color: var(--white);
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  border-radius: 40px;
  background: var(--red);
  position: absolute;
  top: 10px;
  right: 10px;
}

/* stylelint-enable */
