.pageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 22px 40px 0 40px;
}

.listHeader {
  margin-bottom: 10px;
  background: var(--grayedWhite);
}

span.th {
  color: #1d1d26;
  font-weight: 700;
}

.name,
.patient_id {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.name {
  flex: 1 0 120px;
}

.order_lab,
.patient_id {
  flex: 0 0 100px;
}

.order_lab {
  color: var(--darkPurple);
  text-align: center;
}

@media only screen and (min-width: 800px) {
  .patient_id {
    flex: 0 0 120px;
  }
}

.dob {
  flex: 0 0 120px;
  text-align: center;
}

.photo {
  padding: 3px;
  flex: 0 0 45px;
}

.rowPhoto {
  padding: 3px;
  flex: 0 0 45px;
  margin-right: 9px;
}

.age {
  flex: 0 0 60px;
}

.gender {
  flex: 0 0 80px;
}

.arrow {
  flex: 0 0 40px;
  text-align: right;
  font-size: 20px;
  color: var(--alto);
}
