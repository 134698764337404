.listHeader {
  border: 1px solid var(--offWhite);
  background-color: var(--grayedWhite);
  margin-bottom: 10px;
}

.reportName,
.organization,
.user {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* .reportName {
  width: 40%;
} */

.organization,
.user {
  width: 30%;
}

.ordered,
.status {
  width: 20%;
  text-transform: capitalize;
}

.viewed {
  width: 1%;
}

.listRow {
  padding: 0 20px 0 32px;
}

.unreleased {
  padding: 0 20px 0 24px;
  border-left: 6px solid var(--red);
}

.checkGreen {
  color: var(--lightGreen);
}

.checkGrey {
  color: var(--lightGrey);
}
