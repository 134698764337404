.container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  padding: 22px 40px;
  text-align: left;
}

.header {
  margin-bottom: 20px;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 22px;
}

.marginBottom {
  margin-bottom: 7px;
  margin-top: 7px;
}

.invisible {
  visibility: hidden;
}

label {
  text-align: left;
}

.labelError {
  font-size: 12px;
  color: red;
}

.reactivateModalContainer {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  width: 100%;
}

.reactivateModalControls {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin: 20px -30px 0px 50px;
}

.saveInsuranceConfirmationModalControls {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 95%;
  margin: 20px 20px 0px 40px;
}
