.container {
  padding-top: 16px;
}

.subTitle {
  background: #f6f6f6;
}

.info {
  padding: 15px 20px;
}
