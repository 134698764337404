.container {
  border: 1px solid var(--gallery);
  height: 565px;
}
.header {
  border-bottom: 1px solid var(--gallery);
  background-color: var(--grayedWhite);
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
}
.header span {
  font-size: 14px;
  font-weight: 700;
}
.check {
  width: 5%;
}
.code {
  width: 15%;
}
.text {
  width: 80%;
}

.row {
  border: 0 !important;
  border-bottom: 1px solid var(--gallery) !important;
  height: auto;
  margin-bottom: 0;
  padding: 10px 20px;
}
.row:hover {
  background-color: #efefef;
}
.selected span {
  font-weight: 700;
}

.listHeader > div {
  margin-bottom: 10px !important;
}
