.code {
  display: inline-block;
  margin-right: 20px;
  font-weight: bold;
  width: 20%;
  min-width: 20%;
}

.selectedPlaceholder {
  height: 40px;
}

.selected {
  background: var(--darkPurple);
  color: var(--white);
}

.disabled {
  cursor: default;
  color: var(--lightenBlack);
}
.listItem {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 10px;
  border-bottom: 1px solid var(--darkWhite);
  cursor: pointer;
}

.name {
  text-align: left;
  width: 72%;
  word-wrap: break-word;
}
