.content {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.tables {
  display: flex;
}

.search {
  margin-bottom: 20px;
}
.search > div {
  width: 100%;
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

.cancelButton {
  color: var(--black);
  background-color: var(--white);
}
.addButton {
  background-color: var(--pink);
}
.addButton:first-child {
  margin-bottom: 10px;
}

.panelsContainer {
  width: 50%;
}

.aggregatorButtons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 20px;
}

.titleHeader {
  font-weight: bold;
  margin: 5px;
}

.titleHeader {
  font-weight: bold;
  margin: 5px;
}

.noResults {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
}

.list {
  height: 500px;
  border: 1px solid var(--darkWhite);
  border-radius: 4px;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.listItem {
  padding: 10px;
  border-bottom: 1px solid var(--darkWhite);
  cursor: pointer;
}
.testListItem {
  padding: 10px;
  cursor: pointer;
}

.listItemSelected {
  background-color: var(--darkPurple);
  color: var(--white);
}
