.modal {
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}

.primary {
  margin-right: 10px;
}
