.container {
  display: flex;
  flex-direction: column;
}

.detailsItem {
  display: flex;
  flex-flow: row nowrap;
  font-size: 14px;
  color: #696969;
  margin: 5px 0;
  gap: 5px;
}

.reportLink {
  font-size: 14px;
  color: #4183c4;
  margin-bottom: 2px;
  cursor: pointer;
}

.name {
  margin: 0 0 6px;
  font-size: 26px;
}

.documents {
  margin-top: 20px;
}

.summary {
  display: flex;
  justify-content: flex-start;
  margin: 14px 0 16px 0;
}

.summaryValue {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.6px;
  height: 33.6px;
  border-radius: 84px;
  background-color: var(--white);
}

.summaryValue:not(:last-child) {
  margin-right: 6px;
}

.summaryNormal {
  border: 1.7px solid var(--viking);
}

.summaryAbnormal {
  border: 1.7px solid orange;
}

.my10 {
  margin: 10px 0;
}
