.container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  padding: 22px 40px;
}

.header {
  margin-bottom: 20px;
}

.segment:global(.ui.segment) {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 30px;
  border-radius: 0;
}

.paddedBottom {
  padding-bottom: 10px;
}

.toLowerCase input {
  text-transform: lowercase;
}

.ui.selection.dropdown {
  min-width: auto;
  background: red;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 22px;
}

.marginBottom {
  margin-bottom: 7px;
}