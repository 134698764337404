.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 10px 20px;
  background-color: var(--white);
  border-bottom: 1px solid var(--gallery);
  letter-spacing: -0.4px;
}

.container:not(.editing) {
  cursor: pointer;
}

.field {
  margin: 0;
  flex: 0 0 200px;
}

.statusIcon:global(i.icon) {
  margin: 0;
}

.value {
  margin-right: 20px;
  color: var(--black);
}

.statusAbnormal .value {
  color: var(--orange);
}

.statusBorderline .value {
  color: var(--yellow);
}

.name {
  color: var(--black);
  margin-left: 15px;
  flex: 1;
}

.units {
  flex: 0 0 120px;
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.angle {
  font-size: 28px;
  color: var(--alto);
}
