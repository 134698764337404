.root {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  width: auto;
  height: 60px;
  background-color: var(--white);
  border-bottom: 1px solid var(--darkWhite);
  gap: 10px;
}

.contentHolder {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: calc(100% - 60px);
}

.content {
  display: flex;
  width: 100%;
  background-color: var(--midWhite);
  overflow: auto;
}

.projectsDropdown {
  margin-right: 30px;
}

.switchPartnerModal {
  width: 400px;
  flex-direction: column;
}

.savePartnerButton {
  margin-top: 10px;
  width: 120px;
  align-self: flex-end;
}

.label {
  padding: 0px 5px 0px 10px;
  color: gray;
  font-size: 12px;
}

.patientName {
  margin-right: 10px;
  display: inline-block;
  width: 250px;
}

.patientDob {
  margin-right: 10px;
  word-wrap: break-word;
  line-height: 1em;
  white-space: normal;
  outline: 0;
  transform: rotate(0deg);
  min-width: 14em;
  min-height: 2.71428571em;
  background: #fff;
  display: inline-block;
  padding: 0.78571429em 2.1em 0.78571429em 1em;
  color: #1d1d26;
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, width 0.1s ease;
}

/* V4.1 release update */
.v4Sidebar a {
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.v4Sidebar a:first-child {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.v4Sidebar a:nth-child(2) img {
  width: 20px;
}
