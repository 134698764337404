.pageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 22px 40px 0 40px;
}

.modal {
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 5px;
}

.modalHeader {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #cdcdcf;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}

.primary {
  margin-right: 10px;
}

.buttonsContainer {
  text-align: center;
  padding-top: 20px;
}

.centerComponent {
  display: flex;
  justify-content: 'center';
  align-items: 'center';
}

.filterColumn {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modalButtonsContainer {
  align-self: flex-end;
  padding-top: 15px;
}
