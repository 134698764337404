.container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  padding: 22px 40px;
  overflow-y: scroll;
}

.header {
  margin-bottom: 20px;
}

.segment:global(.ui.segment) {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 30px;
  border-radius: 0;
}

.paddedBottom {
  padding-bottom: 10px;
}

.toLowerCase input {
  text-transform: lowercase;
}

.ui.selection.dropdown {
  min-width: auto;
  background: red;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 22px;
  margin-bottom: 22px;
}

.marginBottom {
  margin-bottom: 7px;
}

.formContainer {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

.leftColumn {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 50px;
  width: 270px;
  margin-right: 15px;
}

.rightColumn {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

.profileLabels {
  color: var(--lightenBlack);
  font-size: 15px;
  line-height: 26px;
}

.name {
  font-size: 30px;
  color: var(--black);
  margin-bottom: 0;
}

.modalAlert {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
