.pageContainer {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
}

.formContainer {
  position: relative;
  background: #fff;
  border-radius: 0.28em;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  height: 100%;
  min-width: 560px;
  overflow: hidden;
  padding: 0;
  margin: 0;
  box-shadow: none;
  border: none;
}

.form {
  height: 100%;
}

.formContainer::-webkit-scrollbar {
  display: none;
}

/* Filters */
.partnerAndClientFilters {
  display: flex;
  flex: 1 0 auto;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
}

.partnerAndClientFilters:global(.ui.grid) {
  margin: -1rem;
  margin-bottom: 10px;
}

.pageHeaderContainer {
  flex-wrap: unset;
  min-height: 73px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  background-color: #f7f6f6;
}

.pageTitle {
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.pageTitle > h2 {
  font-size: 24px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: #1d1d26;
}

.filterColumn {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customOrderInputContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.customOrderInput {
  flex-basis: 568px;
  flex-shrink: 1;
  margin-right: 5px;
}

.packagesContainer {
  overflow-y: auto;
  height: calc(100% - 220px);
  padding: 10px 20px;
}

.newLabOrderContainer {
  height: 100%;
}

.buttonsContainer {
  min-height: 77px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  object-fit: contain;
  padding-right: 25px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  background-color: #f7f6f6;
}

/* CheckList items */
.checkListTest {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
}

.checkListTestCode {
  display: inline-block;
  min-width: 150px;
}

.collectionDate {
  display: inline-block;
  margin-left: 20px;
}

.panelContainer {
  margin-bottom: 10px;
  position: relative;
}

.laboratoryWrapper {
  align-items: center;
  display: flex;
}

.laboratoryDropdown {
  margin: 0 38px 0 13px;
}

:global(.ui.grid > .row) > .tabsContainer:global(.column) {
  display: flex;
  align-items: flex-end;
}

.tabsContentClassName {
  display: none;
}

.plusIcon {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  color: var(--white);
  font-weight: 300;
  font-size: 30px;
  background: var(--pink);
  cursor: pointer;
  left: 85px;
}

.subtotalContainer {
  align-items: left;
  background-color: #ececec;
  display: flex;
  height: 60px;
  justify-content: flex-start;
  padding: 20px 0px 0px 30px;
}

.textSubtotal {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: right;
  justify-content: flex-end;
}

.pullRight {
  position: absolute;
  right: 0;
  margin-right: 40px;
}

@media only screen and (max-width: 992px) {
  .partnerAndClientFilters {
    height: 180px;
  }

  .packagesContainer {
    height: calc(100% - 240px);
  }
}

.pricingHeader {
  background: #f5f5f5;
  font-weight: 900;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.pricingBreakdown {
  padding-left: 105px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  width: '100%';
  flex-direction: 'row';
  justify-content: 'flex-start';
}

.pricingBreakdownTxt {
  font-size: 12px;
}

.dropdown {
  background: 0 none !important;
  border: 0 none !important;
  margin-right: 33px;
  padding-top: 13px !important;
}

.checkListHeader {
  min-height: 60px;
}
