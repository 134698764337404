.pageContainer {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.pageTitle {
  margin: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 73px;
  padding-left: 19px;
  margin-bottom: 3px;
  object-fit: contain;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  background-color: #f7f6f6;
}

.pageTitle > h2 {
  font-size: 24px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: #1d1d26;
}

.sectionTitle {
  flex-direction: row;
  display: flex;
}

.sectionTitle > h3 {
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: #1d1d26;
  padding-bottom: 10px;
}

.contentContainer {
  flex-grow: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 80%;
  padding-bottom: 2rem;
}
.contentHeader {
  display: flex;
  justify-content: flex-start;
  padding-left: 40px;
  align-items: center;
  min-height: 60px;
  background-color: #f8f8f8;
  object-fit: contain;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  vertical-align: middle;
}

.contentBody {
  flex: 1;
  margin: 35px 20px 0 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.contentBodyLeft {
  display: flex;
  width: 25%;
  flex-direction: column;
}

.contentBodyCenter {
  /* flex: 1; */
  width: 35%;
  display: flex;
  flex-direction: column;
  padding: 15px 50px 0px 10px;
  margin-left: 20px;
}

.contentBodyRight {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.orderInformation {
  flex: 1;
  overflow: auto;
  flex-direction: column;
}

.orderInfoListItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 15px 0 15px 0;
}

.orderInfoListItem > div:first-child {
  width: 100px;
  min-width: 100px;
  font-weight: normal;
}

.orderInfoListItem > div:last-child {
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #000;
}

.testsListItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.testsListItem > div:first-child {
  min-width: 60px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #000;
}

.orderSubmissionTypeHeader {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 0 15px 0;
}

.orderSubmissionTypeHeader > div:first-child {
  width: 150px;
  min-width: 100px;
  font-weight: normal;
}

.orderSubmissionTypeHeader > div:last-child {
  flex: 1;
}

.orderSubmissionTypePatientPurchase {
  /* flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 15px 0 15px 0;
  overflow: auto; */
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0 15px 0;
}

.orderSubmissionTypeLab {
  max-width: 80%;
  text-align: justify;
}

.orderSubmissionTypePatientPurchase > div:first-child {
  width: 150px;
  min-width: 100px;
  font-weight: normal;
}

.orderSubmissionTypePatientPurchase > div:last-child {
  flex: 1;
  text-align: justify;
  max-width: 400px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #000;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  object-fit: contain;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  min-height: 77px;
  background-color: #f7f6f6;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-right: 5em;
}

.editButton {
  font-size: 14px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #d76acd;
}

.cancelButton > button {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: right;
  color: #1d1d26;
}

.submitButton {
  margin-right: 25px;
}

.emailHeader {
  font-weight: 700;
  text-decoration: underline;
}

.emailContent {
  font-weight: 500;
  text-decoration: none;
}

.commentsField {
  margin: 0;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: Avenir, Arial, Helvetica, sans-serif;
  padding: 0.67857143em 1em;
  background: #fff;
  border: 2px solid rgba(34, 36, 38, 0.15);
  color: #1d1d26;
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
  overflow: hidden;
  min-width: 100%;
  min-height: 10px;
  margin-bottom: 30px;
  resize: none;
}

.addNotesButton {
  margin-left: 10px;
  margin-top: -10px;
  justify-content: flex-end;
}
