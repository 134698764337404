.container {
  display: flex;
  width: 315px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logo {
  /* margin-bottom: 40px; */
  width: 100%;
}

.rememberMe {
  font-size: 14px;
  color: var(--shark);
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.forgotPasswordLink {
  color: var(--shark);
  font-size: 14px;
}
