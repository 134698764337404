@import '~@labsavvyapp/ui-components/lib/theme/theme-variables.css';
@import '~@labsavvyapp/ui-components/lib/theme/theme.css';
@import '~@labsavvyapp/ui-components/lib/index.css';

html,
body,
#root {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

/* TODO: Investigate these overrides */
.ui.grid {
  flex-direction: column;
}

/**
 * Patient > Insurance Modal
 */
/* Tab Menu Items */
.ui.menu a.item {
  color: var(--white);
  width: 50%;
}
.ui.menu a.item:hover {
  color: var(--pink);
}
.ui.menu a.active.item:hover,
.ui.menu a.active.item {
  background-color: var(--pink);
  border-radius: 20px;
  color: var(--white);
}
.ui.menu:not(.vertical) a.item {
  display: block;
  text-align: center;
}
/* Form Input */
.ui.input {
  display: block;
}
.ui.input > input {
  border-width: 1px;
  width: 100%;
}
.ui.modal .actions > .button {
  padding-bottom: 5px;
  padding-top: 5px;
}

/* Coverage Date */
.field {
  display: block !important;
}
.ui.button {
  height: auto;
  line-height: normal;
}

.ui.steps .step.active .title,
.ui.steps .step.completed .title {
  color: var(--darkPurple);
}
.ui.ordered.steps .step.active::before,
.ui.ordered.steps .step.completed::before {
  color: var(--pink);
}

.ui.modal > .close {
  top: 0rem;
  right: 0rem;
  color: #222;
}

.ui.search.error input {
  background-color: #fff6f6;
  border-color: #e0b4b4;
  color: #9f3a38;
  box-shadow: none;
}

/* PL0-155 - fix wrapping long names */
.ui.selection.dropdown .menu > .item {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ui.selection.dropdown .menu > .item > img {
  background: #ccc;
}
