.container {
  width: 100%;
}

.category {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding: 10px 20px;
  background-color: var(--grayedWhite);
}

.category h2 {
  margin-bottom: 0;
}

.categoryInfo {
  background-color: var(--white);
  padding: 10px 20px;
  border-bottom-width: 1px;
  border-bottom-color: var(--grayedWhite);
  border-bottom-style: solid;
}

.testName {
  color: var(--darkPurple);
  font-weight: bold;
}

.categoryImage {
  width: 45px;
  height: 45px;
}

.value {
  margin-right: 20px;
  color: var(--black);
}

.greyFontColor {
  color: grey;
}
.greenFontColor {
  color: var(--viking);
}
.redFontColor {
  color: var(--radicalRed);
}
.orangeFontColor {
  color: orange;
}
.yellowFontColor {
  color: yellow;
}

.educationLink {
  display: inline-flex;
  color: var(--viking);
}
