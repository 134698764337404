.container {
  display: flex;
  flex-direction: column;
}

.resultRow {
  display: flex;
  flex-direction: column;
  padding: 13px 20px;
  text-align: left;
  color: var(--shark);
  border-bottom: solid 1px var(--offWhite);
  margin: 15px 0px 15px 0px;
}

.resultRow:last-child {
  border-bottom: none;
}

.label {
  margin-bottom: 16px;
  opacity: 0.6;
  font-size: 18px;
  line-height: 18px;
}

.name {
  font-weight: 300;
  line-height: 26px;
  font-size: 26px;
}

.testName {
  border-bottom: solid 5px #594a9b;
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  text-align: left;
}
