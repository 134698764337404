.listHeader {
  border: 1px solid var(--offWhite);
  background-color: var(--grayedWhite);
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.listRow {
  margin-bottom: 5px;
  padding: 10px 20px;
}

.th,
.th.orderLab {
  color: #222;
  font-size: 14px;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* columns */
.organization,
.user,
.accession,
.ordered,
.reportName,
.orderid {
  width: 15%;
}
.status,
.trending,
.labReport,
.reviewed,
.viewed {
  text-align: center;
  width: 10%;
}
.orderLab {
  background-image: linear-gradient(#d336c3, #d336c3);
  background-size: 0 100%;
  background-repeat: no-repeat;
  transition: 0.4s;
}

.orderLab:hover {
  background-size: 100% 100%;
}

.unreleased {
  padding: 0 20px 0 24px;
  border-left: 6px solid var(--red);
}

.checkGreen {
  color: var(--lightGreen);
}

.checkGrey {
  color: var(--lightGrey);
}

.s20 {
  font-size: 20px;
}

.box {
  display: flex;
}
.box label {
  display: inline-block;
  font-weight: 700;
  margin-right: 10px;
}

.row {
  display: flex;
  flex-direction: column;
}

.view {
  flex-direction: column;
}

.grey {
  color: grey;
  cursor: default;
}

.green {
  color: green;
}

.pink {
  color: pink;
}

.red {
  color: red;
}

.orange {
  color: orange;
}

.blue {
  color: #4472c4;
}
