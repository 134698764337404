.container {
  margin-top: 10px;
  margin-bottom: 50px;
}

/* Panel left */
.reportNotesHeader {
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.reportNotesTitle {
  font-size: 20px;
  margin: 0;
}

.notesRootContainer {
  margin-bottom: 20px;
  padding-bottom: 20px;
}
