.container {
  display: flex;
  flex-direction: column;
  padding: 20px 35px 0 20px;
  height: 100%;
}

.panel {
  align-items: stretch;
  min-height: max-content !important;
}

.header {
  padding: 15px 20px;
  font-weight: 700;
  border-bottom: 1px solid var(--darkWhite);
}

.content {
  display: flex;
  padding: 15px 20px;
}

.form {
  display: flex;
  flex-wrap: wrap;
}
.group {
  margin-right: 1rem !important;
}

.group:last-child {
  margin-right: inherit !important;
}
